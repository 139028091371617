
import { Vue, Component, Ref } from "vue-property-decorator";
import { UserListDataSource } from "@/data/List/UserListDataSoure";
import RoleEditorDialog from "@/pages/manage/users/components/role-editor-dialog.vue";
import ResetPasswordDialog from "@/pages/manage/users/components/reset-password-dialog.vue";
import { UserObjectDataSource } from "@/data/Object/UserObjectDataSource";
import { mdiDownload, mdiPlusCircleOutline, mdiUpload } from "@mdi/js";
import FileUploaderDialog from "@/components/helpers/file-uploader-dialog.vue";
import DataList from "@/components/common/data/list.vue";
import {ObjectDataSource} from "@/data/Object/ObjecDatatSource";
import {Filter} from "@/cms-services/filter";
@Component({
  components: { RoleEditorDialog, ResetPasswordDialog, FileUploaderDialog },
})
export default class UserList extends Vue {
  loaded:boolean = false;
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "consent",
  });

  get caption(): any {
    return this.dataSource.model?.caption;
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Согласия",
        to: "/manage/consents/",
        exact: true,
      },
      {
        text: this.dataSource.model?.caption,
        disabled: true,
      },
    ];
  }

  headers: Array<any> = [
    {
      text: "ID",
      value: "id",
      sortable: true,
      width: 100,
    },
    {
      text: "Дата создания",
      value: "createDate",
      sortable: false,
    },
    {
      text: "Email",
      value: "email",
      sortable: false,
    },
    {
      text: "ФИО",
      value: "fullName",
      sortable: false,
    },

    {
      text: "Подтверждение",
      value: "emailConfirmed",
      sortable: false,
    },

    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
  ];
  @Ref("dataList") dataList!: DataList;
  userProfileDataSource: UserListDataSource = new UserListDataSource({
    pageIndex: 1,
    pageSize: 15,
  });

  createItemActions: any = {
    synhronUserName: (model) => {
      model.userName = model.email;
    },
  };


  $message: any;
  $confirm: any;
  icons: any = {
    circlePlus: mdiPlusCircleOutline,
    upload: mdiUpload,
    download: mdiDownload,
  };
  async downloadExcel() {
    try {
      await this.userProfileDataSource.downloadExcelByConsent(this.dataSource.model.id);
    } catch (error) {
      this.$message((error as any)?.response?.data, "error");
    }
  }

  async created(){
    await this.dataSource.get();

    this.userProfileDataSource.config.filter = JSON.stringify([
      new Filter("consentId", +this.dataSource.model.id)
    ])

    await this.userProfileDataSource.get();
    this.loaded = true;
  }


}
