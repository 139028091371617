import { IBaseConfig } from "@/ioc/types";
import axios from "axios";
import { toQueryString } from "@/cms-services/helpers";
import { ListDataSource } from "./ListDataSource";

export class UserListDataSource extends ListDataSource {
  constructor(config: IBaseConfig) {
    super({
      className: "user",
      config,
    });
  }
  public async findUser(fieldName: string, fieldValue: string) {
    try {
      const url = `${this.baseUrl}/find/${toQueryString({
        fieldName,
        fieldValue,
      })}`;
      const { data } = await axios.get(url);
      return data;
    } catch (error: any) {
      throw error;
    }
  }

  public async uploadCsv(event: any) {
    try {
      await axios.post(`${this.baseUrl}/upload/csv`, event.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        onUploadProgress(e) {
          event.onProgress(e, event.file);
        },
      });
    } catch (err) {
      throw err;
    }
  }

  public async downloadExcel() {
    try {
      const { data } = await axios.get(`${this.baseUrl}/download/xlsx`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(data);
      var link = document.createElement("a");
      link.download = "Список пользователей.xlsx";
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      throw error;
    }
  }

  public async downloadExcelByConsent(consentId:number) {
    try {
      const { data } = await axios.get(`${this.baseUrl}/download/xlsx/consent/${consentId}`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(data);
      var link = document.createElement("a");
      link.download = "Список пользователей.xlsx";
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      throw error;
    }
  }
}

export class EventUserListDataSource extends ListDataSource {
  constructor(config: IBaseConfig) {
    super({
      className: "event",
      config,
    });
  }
  public async findUser(fieldName: string, fieldValue: string) {
    try {
      const url = `${this.baseUrl}/users/${toQueryString({
        fieldName,
        fieldValue,
      })}`;
      const { data } = await axios.get(url);
      return data;
    } catch (error: any) {
      throw error;
    }
  }


}
